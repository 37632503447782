import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from "yup";
import { Image } from "react-bootstrap";
import { ModalFA } from "../modal/modalFA";
import { ModalInfoHelp } from "../modal/modalInfoHelp";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers/";
import * as authAction from "../redux/authAction";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required('Field is required')
    .email('Wrong email format'),
  password: Yup.string()
    .required('Field is required')
})

export default function Login() {
  const dispatch = useDispatch();
  const authReducer = useSelector(state => state.auth);
  const [showModalFA, setShowModalFA] = useState(false);
  const [showModalInfoHelp, setShowModalInfoHelp] = useState(false);
  const { register, handleSubmit, setError, errors, watch } = useForm({
    resolver: yupResolver(validationSchema)
  });

  function onSubmit(values){
    dispatch(authAction.loginRequest({
      username: values.email,
      password: values.password
    }))
  }

  useEffect(() => {
    if(authReducer.isLoading === false && authReducer.isError === false && authReducer.loginInfo !== null){
      setShowModalFA(true);
    } else if(authReducer.isLoading === false && authReducer.isError === true){
      dispatch(authAction.loginReset());
      setError("password", {
        type: "manual",
        message: authReducer.errorMsg.message
      });
    }
  }, [authReducer])

  function onClickHelp(){
    setShowModalInfoHelp(true);
  }

  return (
    <div
      className="login-form login-signin shadow-sm p-3 mb-5 bg-body rounded"
      id="kt_login_signin_form"
      style={{
        width: "350px",
        background: "#dddd",
        padding: "20px",
        boxSizing: "border-box",
        borderRadius: "5px",
      }}
    >
      <div className="text-center mb-5 mb-lg-0">
        <Image
          src={`${toAbsoluteUrl("/media/nkf/logo-nkf.png")}`}
          fluid
          style={{ height: 75, marginTop: 20, maxHeight: '50px', marginBottom: 20 }}
        />
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Email"
            type="email"
            className={`form-control form-control-solid h-300p py-5 px-6 ${errors?.email && 'is-invalid'}`}
            name="email"
            ref={register}
          />
          { errors?.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{errors?.email?.message}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Password"
            type="password"
            className={`form-control form-control-solid h-300p py-5 px-6 ${errors?.password && 'is-invalid'}`}
            name="password"
            ref={register}
          />
          { errors?.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{errors?.password?.message}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center mb-0">
          <div>
            <Link
              to="/auth/forgot-password"
              className="text-black-50 text-hover-danger my-3 mr-2"
              id="kt_login_forgot"
            >
              Forgot Password
            </Link>
            <br/>
            <span className="text-primary text-hover-primary" style={{cursor: 'pointer'}} onClick={onClickHelp}>Need Help?</span>
          </div>
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={authReducer.isLoading}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
          >
            <span>Sign In</span>
            {authReducer.isLoading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>
      <ModalFA 
        show={showModalFA}
        onHide={()=>setShowModalFA(false)}
      />
      <ModalInfoHelp 
        show={showModalInfoHelp}
        onHide={()=>setShowModalInfoHelp(false)}
      />
    </div>
  );
}