import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import * as auth from "../_redux/authRedux";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from "yup";
import * as authAction from "../redux/authAction";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required('Field is required')
    .email('Wrong email format'),
  dob: Yup.string()
    .required('Field is required')
})

function ForgotPassword(props) {
  const dispatch = useDispatch();
  const authReducer = useSelector(state => state.auth);
  const [isRequested, setIsRequested] = useState(false);
  const { register, handleSubmit, setError, errors, watch } = useForm({
    resolver: yupResolver(validationSchema)
  });

  function onSubmit(values){
    dispatch(authAction.forgotRequest({
      email: values.email,
      dob: values.dob
    }));
  }

  useEffect(() => {
    dispatch(authAction.forgotReset());
  }, [])

  useEffect(() => {
    if(authReducer.isLoading === false && authReducer.isError === true){
      dispatch(authAction.forgotReset());
      setError("dob", {
        type: "manual",
        message: authReducer.errorMsg.message
      });
    }
  }, [authReducer])

  return (
    <>
      {isRequested && <Redirect to="/auth" />}
      {!isRequested && (
        <div
          className="login-form login-forgot shadow p-3 mb-5 rounded "
          style={{
            display: "block",
            background: "#dddd",
            padding: "20px",
            boxSizing: "border-box",
            borderRadius: "10px",
          }}
        >
          <div className="text-center mt-10 mb-0 mb-lg-0">
            <h3 className="font-size-h1 m-lg-0 text-dark">Forgot Password ?</h3>
            <div className="text-muted-primary font-weight-bold mb-5">
              Enter your email and date of birth to reset your password
            </div>
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="Email"
                type="email"
                className={`form-control form-control-solid h-300p py-5 px-6 ${errors?.email && 'is-invalid'}`}
                name="email"
                ref={register}
              />
              { errors?.email ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{errors?.email?.message}</div>
                </div>
              ) : null}
            </div>
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="yyyy-mm-dd"
                type="date"
                className={`form-control form-control-solid h-300p py-5 px-6`}
                name="dob"
                ref={register}
              />
              { errors?.dob ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{errors?.dob?.message}</div>
                </div>
              ) : null}
            </div>
            <div
              className="form-group d-flex flex-wrap flex-center"
              style={{ marginBottom: "10px" }}
            >
              {
                authReducer.forgotPassword?.data?.success &&
                <span>{"Please check your email"}</span>
              }
            </div>
            <div
              className="form-group d-flex flex-wrap flex-center"
              style={{ marginBottom: "10px" }}
            >
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                disabled={authReducer.isLoading}
              >
                <span>Submit</span>
                {authReducer.isLoading && <span className="ml-3 spinner spinner-white"></span>}
              </button>
              <Link to="/auth">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-light font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  Cancel
                </button>
              </Link>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword));