import React from "react";
import { Header } from "../../component/UploadNavbar";
import { InfoDetail } from "./InfoDetail/InfoDetail";

export function DonationDetailPage(props) {
  return (
    <>
      <Header />
      <InfoDetail />
    </>
  );
}
