import nric from "nric";
import * as Yup from "yup";
import { validationFormatDate, validationUENNumber } from "./helpers";

const validateNRIC = (value) => {
  if(!value) {
    return true;
  }
  return nric.validate(value);
}

export const givingSGSchema = Yup.object().shape({
  donation_type: Yup.string()
    .test("donation_type", "must be: One-Time / Recurring", function(value) {
      return (
        value?.toLowerCase() === "one-time" ||
        value?.toLowerCase() === "recurring"
      );
    })
    .required("Donation Type is required"),
  donor_type: Yup.string()
    .test(
      "donor_type",
      "must be: Organisation / Individual / Anonymous",
      function(value) {
        return (
          value?.toLowerCase() === "organisation" ||
          value?.toLowerCase() === "individual" ||
          value?.toLowerCase() === "anonymous"
        );
      }
    )
    .required("Donor Type is required"),
  account_name: Yup.string().when("donor_type", {
    is: (value) => value?.toLowerCase() === "organisation",
    then: Yup.string().required("Account Name is required"),
    otherwise: Yup.string(),
  }),
  donor_email: Yup.string().when("donor_type", {
    is: (value) => value?.toLowerCase() === "anonymous",
    then: Yup.string(),
    otherwise: Yup.string().required("Donor Email is required"),
  }),
  amount: Yup.number().required("Amount is required"),
  batch_date: Yup.string().test("batch_date", "Invalid date", function(value) {
    return validationFormatDate(value);
  }),
  block_number: Yup.string(),
  building_name: Yup.string(),
  campaign_name: Yup.string().required("Campaign Name is required"),
  distribution_code_name: Yup.string(),
  donation_date: Yup.string()
    .test("donation_date", "Invalid date", function(value) {
      return validationFormatDate(value);
    })
    .required("Donation Date is required"),
  donation_ref: Yup.string(),
  donor_name: Yup.string().when("donor_type", {
    is: (value) => value?.toLowerCase() === "anonymous",
    then: () => Yup.string(),
    otherwise: () => Yup.string().required("Donor Name is required"),
  }),
  tdr: Yup.string()
    .when("donor_type", {
      is: (value) => value?.toLowerCase() === "anonymous",
      then: Yup.string()
        .test("tdr", "TDR must be No if donor type is Anonymous", function(
          value
        ) {
          return value?.toLowerCase() === "no";
        })
        .required("TDR is required"),
      otherwise: () =>
        Yup.string()
          .test("tdr", "TDR must be Yes/No", function(value) {
            return (
              value?.toLowerCase() === "yes" || value?.toLowerCase() === "no"
            );
          })
          .required("TDR is required"),
    })
    .required("TDR is required"),
  donor_nric_fin: Yup.string()
    .when("donor_type", {
      is: (value) => value?.toLowerCase() === "anonymous",
      then: Yup.string(),
    })
  .when("tdr", {
    is: (value) => value?.toLowerCase() === "yes",
    then: () =>
      Yup.string().when("donor_type", {
        is: (value) =>
          value?.toLowerCase() === "organisation" && value?.toLowerCase() !== "anonymous",
        then: Yup.string()
          .test("donor_nric_fin", "Invalid NRIC", function(value) {
            return validationUENNumber(value);
          })
          .required("Donor Nric Fin is required"),
        otherwise: Yup.string()
          .test("donor_nric_fin", "Invalid NRIC", function(value) {
            return validateNRIC(value);
          })
          .required("Donor Nric Fin is required"),
      }),
    otherwise: () =>
      Yup.string().when("donor_type", {
        is: (value) =>
          value?.toLowerCase() === "organisation" && value?.toLowerCase() !== "anonymous",
        then: Yup.string().test("donor_nric_fin", "Invalid NRIC", function(
          value
        ) {
          return validationUENNumber(value);
        }),
        otherwise: Yup.string().test(
          "donor_nric_fin",
          "Invalid NRIC",
          function(value) {
            return validateNRIC(value);
          }
        ),
      }),
  }),
  file_type: Yup.string(),
  postal_code: Yup.string(),
  remarks: Yup.string(),
  salutation: Yup.string(),
  street_name: Yup.string(),
  unit_number: Yup.string(),
});
