import moment from "moment";
import nric from "nric";

export const validationUENNumber = (str) => {
  if (!str) return true
  if (str.length === 0) {
    return true
  }
  if (![9, 10].includes(str.length)) {
    return false
  }
  if (![9, 10].includes(str.length)) {
    return false
  } else {
    const entityTypeIndicator = [
      "LP",
      "LL",
      "FC",
      "PF",
      "RF",
      "MQ",
      "MM",
      "NB",
      "CC",
      "CS",
      "MB",
      "FM",
      "GS",
      "GA",
      "GB",
      "DP",
      "CP",
      "NR",
      "CM",
      "CD",
      "MD",
      "HS",
      "VH",
      "CH",
      "MH",
      "CL",
      "XL",
      "CX",
      "RP",
      "TU",
      "TC",
      "FB",
      "FN",
      "PA",
      "PB",
      "SS",
      "MC",
      "SM",
    ]
    const uen = str?.toUpperCase()
    const uenStrArray = uen?.split("")

    // (A) Businesses registered with ACRA
    if (uenStrArray?.length === 9) {
      // check that last character is a letter
      if (!isNaN(parseInt(uenStrArray[uenStrArray.length - 1]))) {
        return false
      }

      for (let i = 0; i < uenStrArray.length - 1; i++) {
        // check that first 8 letters are all numbers
        if (isNaN(parseInt(uenStrArray[i]))) {
          return false
        }
      }

      // (A) Businesses registered with ACRA (SUCCESS)
      return true
    } else if (uenStrArray?.length === 10) {
      // check that last character is a letter
      if (!isNaN(parseInt(uenStrArray[uenStrArray.length - 1]))) {
        return false
      }

      // (B) Local companies registered with ACRA
      if (
        !isNaN(parseInt(uenStrArray[0])) &&
        !isNaN(parseInt(uenStrArray[1])) &&
        !isNaN(parseInt(uenStrArray[2])) &&
        !isNaN(parseInt(uenStrArray[3]))
      ) {
        // check that 5th to 9th letters are all numbers
        if (
          !isNaN(parseInt(uenStrArray[4])) &&
          !isNaN(parseInt(uenStrArray[5])) &&
          !isNaN(parseInt(uenStrArray[6])) &&
          !isNaN(parseInt(uenStrArray[7])) &&
          !isNaN(parseInt(uenStrArray[8]))
        ) {
          // (B) Local companies registered with ACRA (SUCCESS)
          return true
        } else {
          return false
        }
      }
      // (C) All other entities which will be issued new UEN
      else {
        // check that 1st letter is either T or S or R
        if (uenStrArray[0] !== "T" && uenStrArray[0] !== "S" && uenStrArray[0] !== "R") {
          return false
        }

        // check that 2nd and 3rd letters are numbers only
        if (isNaN(parseInt(uenStrArray[1])) || isNaN(parseInt(uenStrArray[2]))) {
          return false
        }

        // check that 4th letter is an alphabet
        if (!isNaN(parseInt(uenStrArray[3]))) {
          return false
        }

        // check entity-type indicator
        let entityTypeMatch = false
        const entityType = String(uenStrArray[3]) + String(uenStrArray[4])
        for (let j = 0; j < entityTypeIndicator.length; j++) {
          if (String(entityTypeIndicator[j]) === String(entityType)) {
            entityTypeMatch = true
          }
        }
        if (!entityTypeMatch) {
          return false
        }

        // check that 6th to 9th letters are numbers only
        if (
          isNaN(parseInt(uenStrArray[5])) ||
          isNaN(parseInt(uenStrArray[6])) ||
          isNaN(parseInt(uenStrArray[7])) ||
          isNaN(parseInt(uenStrArray[8]))
        ) {
          return false
        }

        // (C) All other entities which will be issued new UEN (SUCCESS)
        return true
      }
    }
  }

  return true
}

export const validationFormatDate = (date) => {
  if (date) {
    const format = "DD-MM-YYYY";
    const isValid = moment(date, format, true).isValid();
    return isValid;
  }
};

export const formatDate = (date) => {
  const format = "DD-MM-YYYY";
  return moment(date).format(format);
}

export const validationAccountName = (name, donorType) => {
  if (donorType?.toLowerCase() === "organisation") {
    const isValid = name?.length > 0;
    return isValid;
  }
  return true;
};

export const validationEmail = (email) => {
    const isValid = /\S+@\S+\.\S+/.test(email);
    return isValid;
};

export const validationTDR = (tdr, donorType) => {
  const isValid = tdr?.toLowerCase() === "yes" || tdr?.toLowerCase() === "no"
  if(donorType?.toLowerCase() === "anonymous"){
    return tdr?.toLowerCase() === "no";
  }else {
    return isValid;
  }
};

export const validationNRIC = (nricData, donorType, tdr) => {
  if(donorType?.toLowerCase() !== "anonymous" && tdr?.toLowerCase() === "yes"){
    if(donorType?.toLowerCase() === "organisation"){
      return validationUENNumber(nricData);
    }else if(donorType?.toLowerCase() === "individual"){
      return nric.validate(nricData);
    }
  }else {
    return true;
  }
};

export const getNameField = (field) => {
  return field.split('_').map(x => x.charAt(0).toUpperCase() + x.substr(1)).join(' ');
}

export const validationDonorType = (donorType) => {
  const donor = donorType?.toLowerCase();
  const isValid = donor === "organisation" || donor === "individual" || donor === "anonymous";
  return isValid;
}

export const validationsData = (key, data, donorType, tdr) => {
  const donorTypeLower = donorType?.toLowerCase();
  if(/date/i.test(key)) {
    return validationFormatDate(data);
  }else if(/name/i.test(key) && donorTypeLower === "organisation" && /account/i.test(key)) { 
    return validationAccountName(data, data.donor_type);
  }else if(/email/i.test(key) && donorTypeLower !== "anonymous") {
    return validationEmail(data);
  }else if(/donor_name/i.test(key) && donorTypeLower !== "anonymous") {
    return data?.length > 0;
  } else if(/tdr/i.test(key)) {
    return validationTDR(data, donorType);
  } else if(/nric/i.test(key)) {
    return validationNRIC(data, donorType, tdr);
  }else if(/donor_type/i.test(key)) {
    return validationDonorType(data);
  }else return true;
}

export const getErrorMessage = (key, data, donorType, tdr) => {
  const donorTypeLower = donorType?.toLowerCase();
  if(/date/i.test(key)) {
    if(!validationFormatDate(data)) {
      return getNameField(key)+": Invalid date format, must be dd-mm-yyyy";
    }
  }else if(/name/i.test(key) && donorTypeLower === "organisation" && /account/i.test(key)) {
    if(!validationAccountName(data, data.donor_type)) {
      return getNameField(key)+": Account name is required";
    }
  }else if(/email/i.test(key) && donorTypeLower !== "anonymous") {
    if(data?.length === 0) {
      return getNameField(key)+": Email is required";
    }else if(!validationEmail(data)) {
      return getNameField(key)+": Invalid email format";
    }
  }else if(/donor_name/i.test(key) && donorTypeLower !== "anonymous") {
    return data?.length <= 0 && getNameField(key)+": Donor name is required";
  } else if(/tdr/i.test(key)) {
    if(donorTypeLower === "anonymous") {
      if(data?.toLowerCase() !== "no") {
        return getNameField(key)+": TDR must be No";
      }
    }else if(data?.toLowerCase() !== "yes" && data?.toLowerCase() !== "no") {
      return getNameField(key)+": TDR must be Yes or No";
    }
  } else if(/nric/i.test(key)) {
    if(donorType !== "Anonymous" && tdr === "Yes" && data?.length === 0) {
      return getNameField(key)+": NRIC is required";
    }else if(!validationNRIC(data, donorType, tdr)) {
      return getNameField(key)+": Invalid NRIC format";
    }
  } else if(/donor_type/i.test(key)) {
    if(!validationDonorType(data)) {
      return getNameField(key)+": Invalid donor type. must be: Organisation / Individual / Anonymous";
    }
  }
}