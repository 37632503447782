import React, {useState} from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import {get} from 'lodash';
import Swal from 'sweetalert2'

export function ModalInsert({
  onHide,
  show,
  fields = [],
  afterSubmit = () => {}
}) {

  const [formValue, setFormValue] = useState({})

  function changeValue(value, key){
    setFormValue(prevState => ({...prevState, [key]: value}))
  }

  function submit(){
    let allEmpty = true;
    Object.keys(formValue).forEach(x => {
      if(formValue[x] !== '') {
        allEmpty = false;
        return;
      }
    })
    if(allEmpty){
      Swal.fire(
        'Oops',
        `Error : Cant submit empty data`,
        'error'
      )
    }else{
      afterSubmit(formValue)
      onHide()
      setFormValue({})
    }
  }
  
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Row for Batch Insert</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      {fields.length > 0 && 
        <Row>
          {fields.map((key, index) => (
            ['_id','__v','file_type','salesforce_id'].includes(key) ? <></> :
            <Col xs={12} md={6} key={index}>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>{key.split('_').map(x => x.charAt(0).toUpperCase() + x.substr(1)).join(' ')}</Form.Label>
                <Form.Control type="Text" value={get(formValue, key,'')} onChange={(e) => changeValue(e.target.value, key)}/>
              </Form.Group>
            </Col>
          ))}
        </Row>
      }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={submit}>
          Add Row
        </Button>
      </Modal.Footer>
    </Modal>
  );
}