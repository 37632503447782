import React from "react";
import { Header } from '../../component/UploadNavbar'
import { TableDonationList } from "./TableDonationList/TableDonationList";

export function DonationListPage() {
  return (
    <>
      <Header />
      <TableDonationList />
    </>
  );
}
