import { yupResolver } from "@hookform/resolvers/yup";
import { get } from "lodash";
import moment from "moment";
import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { Controller, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import * as donationRepo from "../../../datasources/donationRepo";

const createValidationSchema = (fields) => {
  const requiredFields = ["donor_email", "donation_date", "batch_date"];
  const shape = fields.reduce((acc, field) => {
    if (/email/i.test(field)) {
      acc[field] = yup
        .string()
        .email("Invalid email")
        .required("Email is required");
    } else if (/amount/i.test(field) || field === "donation") {
      acc[field] = yup.string().matches(/^\d+$/, "Amount must be a number");
    }
    // else if (/phone/i.test(field)) {
    //   acc[field] = yup
    //     .string()
    //     .matches(/^[689]\d{7}$/i, "Invalid phone number");
    // }
    else if (requiredFields.includes(field)) {
      acc[field] = yup.string().required("Field is required");
    } else {
      acc[field] = yup.string(); // default to string for other fields
    }
    return acc;
  }, {});

  return yup.object().shape(shape);
};

export function ModalCreate({
  onHide,
  show,
  data,
  afterSubmit = () => {},
  file_type,
}) {
  const [formValue, setFormValue] = useState({});
  const validationSchema = createValidationSchema(
    data.filter((x) => !["salesforce_id"].includes(x))
  );

  const typeField = (type) => {
    if (/email/i.test(type)) {
      return "email";
    } else if (/date/i.test(type)) {
      return "date";
    } else {
      return "text";
    }
  };

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: data.reduce((acc, field) => {
      acc[field] = get(formValue, field, "");
      return acc;
    }, {}),
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const dateFormat = (date) => moment(date).format("DD/MM/YYYY");

  function changeValue(value, key) {
    if (/date/i.test(key)) {
      setValue(key, dateFormat(value), { shouldValidate: true });
    } else {
      setValue(key, value, { shouldValidate: true });
    }
    setFormValue((prevState) => ({ ...prevState, [key]: value }));
  }

  async function submitData(e) {
    try {
      if (Object.keys(e).length > 0) {
        let formData = { ...e, file_type };
        await donationRepo.apiUploadCreate({ data: formData });
        Swal.fire("Data Created!", "Data Success Created!", "success");
        setFormValue({});
        afterSubmit();
        onHide();
      } else {
        Swal.fire("Error", `Please fill the form!`, "error");
      }
    } catch (error) {
      Swal.fire("Error", `${error.message}`, "error");
    }
  }

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Add Record</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(submitData)}>
        <Modal.Body>
          <Row>
            {data
              .filter((x) => !["salesforce_id"].includes(x))
              .map((value, i) => (
                <Col xs={12} md={6} key={i}>
                  <Form.Group
                    className="mb-3"
                    controlId={`formControl-${value}`}
                  >
                    <Form.Label>
                      {value
                        .split("_")
                        .map((x) => x.charAt(0).toUpperCase() + x.substr(1))
                        .join(" ")}
                    </Form.Label>
                    <Controller
                      name={value}
                      control={control}
                      render={({ field }) => (
                        <Form.Control
                          {...field}
                          type={typeField(value)}
                          value={get(formValue, value, "")}
                          onChange={(e) => changeValue(e.target.value, value)}
                          isInvalid={errors[value]}
                        />
                      )}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors[value]?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              ))}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Cancel
          </Button>
          <Button variant="primary" type="submit">
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
