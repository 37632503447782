import React, { useEffect, useState } from "react";
import { toAbsoluteUrl } from "../../../_helpers";
import { Button } from "react-bootstrap";
import "./ListInstagramFeed.css";

export function ListInstagramFeed({ className }) {
  const [urlPhoto, setUrlPhoto] = useState(null);
  const [imageFeed, setImageFeed] = useState(null);
  const [userName, setUsername] = useState(null);
  const [followersCount, setFollowersCount] = useState(null);
  const [caption, setCaption] = useState(null);
  const [likesCount, setLikesCount] = useState(null);
  const [urlFeed, setUrlFeed] = useState(null);
  const [isLoadFeed, setIsLoadFeed] = useState(false);

  const accessToken =
    "EAAFi363HJXoBAAuOX0WFo4q9HdlZB21RT3ZCKuxjvQHMcm7x1CCRzhPTwAs7puaeW0qOlxS1vlQs6QiW0FLs2FyRiLlz4FKHeBc5kBuZCi4A7Px1Bd2QoZA9rVvDngCAQGilAHOXuvBzv6JGm7UxJlz0i3WQKoBFxo3HSlBWzAZDZD";

  function fetchProfileData() {
    fetch(`https://graph.facebook.com/v11.0/17841448392950438?fields=biography,profile_picture_url,id,username,name,followers_count,media{media_url,comments_count,like_count,caption,permalink}&access_token=${accessToken}`)
      .then((res) => res.json())
      .then((res) => {
        setFollowersCount(res.followers_count);
        setUrlPhoto(res?.profile_picture_url);
        setUsername(res.username);
        setImageFeed(res.media.data[1]?.media_url);
        setCaption(res.media.data[1]?.caption);
        setUrlFeed(res.media.data[1]?.permalink);
        setLikesCount(res.media.data[1]?.like_count);
        setTimeout(() => {
          setIsLoadFeed(true);
        }, 300);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    fetchProfileData();
  }, []);

  function kFormatter(num) {
    return Math.abs(num) > 999
      ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
      : Math.sign(num) * Math.abs(num);
  }

  return (
    <div className={`card card-custom ${className}`}>
      {/* Header */}
      <div className="card-header border-0">
        <h3 className="card-title font-weight-bolder text-dark">
          Instagram Page
        </h3>
      </div>

      {/* Body */}
      <div className="card-body pt-2">
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ maxWidth: 500 }}
        >
          {isLoadFeed && (
            <div className="instagram-container">
              <div className="header-ig">
                <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%"}}>
                  <div className="d-flex">
                    <img
                      className="profile-pic"
                      src={urlPhoto}
                      alt="profile_pic"
                      style={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "40px",
                        marginTop: "4px",
                      }}
                    />
                    <div className="profil ml-3 mb-3">
                      <div className="username">
                        <div
                          style={{
                            margin: "0",

                            color: "black",
                            display: "flex",
                            alignItems: "flex-end",
                            fontFamily:
                              "Helvetica Neue,Helvetica,Roboto,Arial,sans-serif",
                            fontSize: "13px",
                          }}
                        >
                          <span>{userName}</span>
                        </div>
                      </div>
                      <span
                        className="followers-count"
                        style={{
                          fontFamily:
                            "Helvetica Neue,Helvetica,Roboto,Arial,sans-serif",
                        }}
                      >
                        {`${kFormatter(followersCount)} followers`}
                      </span>
                    </div>
                  </div>

                  <Button
                    size="sm"
                    style={{maxHeight: 30, marginRight: 10}}
                    onClick={() => {
                      window.open("https://www.instagram.com/nkfsg", "_blank");
                    }}
                  >
                    <span>View Profile</span>
                  </Button>
                </div>
              </div>
              <div>
                <a href={urlFeed} target="_blank">
                  <img
                    className="img-ig"
                    src={imageFeed}
                  />
                </a>
              </div>
              <a
                className="text-view-ig p-2 mt-2"
                href="https://www.instagram.com/nkfsg"
                target="_blank"
              >
                <p
                  style={{
                    fontSize: "14px",
                    color: "#0095f6",
                    marginBottom: "10px",
                    fontFamily:
                      "Helvetica Neue,Helvetica,Roboto,Arial,sans-serif",
                  }}
                >
                  View More on Instagram
                </p>
                <hr style={{ margin: "1px" }} />
              </a>
              <a
                className="icon-ig"
                style={{ display: "flex", justifyContent: "space-between" }}
                href="https://www.instagram.com/nkfsg"
                target="_blank"
              >
                <div className="icon-left">
                  <img
                    className="icon-svg"
                    src={toAbsoluteUrl("media/svg/icons/General/Love.svg")}
                    style={{
                      width: "33px",
                      height: "33px",
                      strokeWidth: "3px",
                    }}
                  />

                  <img
                    className="icon-svg"
                    src={toAbsoluteUrl("media/svg/icons/General/Comment.svg")}
                    style={{
                      width: "24px",
                      height: "24px",
                      marginLeft: "2px",
                      strokeWidth: "3px",
                    }}
                  />
                  <img
                    src={toAbsoluteUrl("media/svg/icons/General/Share.svg")}
                    style={{
                      width: "24px",
                      height: "24px",
                      marginLeft: "6px",
                      strokeWidth: "3px",
                    }}
                  />
                </div>
                <div className="icon-right">
                  <img
                    src={toAbsoluteUrl("media/svg/icons/General/Bookmark2.svg")}
                    style={{
                      width: "24px",
                      height: "24px",
                      strokeWidth: "10",
                      stroke: "black",
                    }}
                  />
                </div>
              </a>
              <div className="likes-count p-2">
                <p style={{ fontSize: "13px", margin: "0", color: "black" }}>
                  {`${likesCount} likes`}
                </p>
                <p
                  style={{
                    fontSize: "13px",
                    marginBottom: "0",
                    marginTop: "10px",
                    color: "black",
                  }}
                >
                  {userName}
                </p>
              </div>
              <a
                className="caption p-2"
                href="https://www.instagram.com/nkfsg"
                target="_blank"
              >
                <p style={{ fontSize: "13px", margin: "0", color: "#00376b" }}>
                  {caption}
                </p>
                <p
                  style={{
                    fontSize: "13px",
                    margin: "0",
                    color: "#8e8e8e",
                    marginTop: 5,
                  }}
                >
                  view all comments
                </p>
                <hr />
              </a>
              <a
                className="footer-ig p-2"
                style={{ display: "flex", justifyContent: "space-between" }}
                href="https://www.instagram.com/nkfsg"
                target="_blank"
              >
                <div>
                  <p
                    style={{ fontSize: "13px", margin: "0", color: "#8e8e8e" }}
                  >
                    Add a comment...
                  </p>
                </div>
                <div>
                  <img
                    src={toAbsoluteUrl(
                      "media/svg/icons/General/Logo-Instagram.svg"
                    )}
                    style={{
                      width: "24px",
                      height: "24px",
                      marginLeft: "6px",
                    }}
                  />
                </div>
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
