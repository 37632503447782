import React from "react";
import {
  Nav,
  Navbar,
  NavDropdown,
} from "react-bootstrap";
import { BiCoinStack } from "react-icons/bi";
import { FaCloud, FaSignOutAlt } from "react-icons/fa";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import "./Navbar.css";

export function Header() {
  return (
    <>
      <Navbar
        variant="light"
        className="container-navbar"
        style={{ paddingLeft: "25px" }}
      >
        <Navbar.Brand className="ffth-logo" href="#home">
          <img
            src={toAbsoluteUrl("/media/nkf/logo-nkf.png")}
            style={{ maxWidth: "150px" }}
          />
        </Navbar.Brand>
        <Nav className="container-link d-flex justify-content-end">
          <Nav.Link className="link-navbar" href="/">
            <FaCloud style={{ marginRight: "3px" }} />
            Importation Page
          </Nav.Link>
          <BiCoinStack style={{ marginTop: "10px" }} />
          <NavDropdown
            style={{ fontFamily: "Helvetica Neue" }}
            title="Temporary Data"
          >
            <NavDropdown.Item href="/donation-list#give_asia">
              Temporary Data - Give Asia
            </NavDropdown.Item>
            <NavDropdown.Item href="/donation-list#giving_sg">
              Temporary Data - Giving Sg
            </NavDropdown.Item>
            <NavDropdown.Item href="/donation-list#axs">
              Temporary Data - AXS
            </NavDropdown.Item>
            {/* <NavDropdown.Item href="/donation-list#sggiving">
              Temporary Data - SGGiving
            </NavDropdown.Item> */}
            <NavDropdown.Item href="/donation-list#singtel_dash">
              Temporary Data - Singtel Dash
            </NavDropdown.Item>
            <NavDropdown.Item href="/donation-list#simply_giving">
              Temporary Data - Simply Giving
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar>
    </>
  );
}
