import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
// import {get} from 'lodash'
import { Formik, Form as Formmik } from 'formik';
import moment from "moment";
import Swal from 'sweetalert2';
import * as donationRepo from "../../../datasources/donationRepo";
import { givingSGSchema } from "../../DonationDetail/InfoDetail/validation";

export function ModalFilter({
  onHide,
  show,
  data,
  afterSubmit = () => {}
}) {
  const [formValue, setFormValue] = useState({})
  const formatDate = (date) => moment(date).format("DD-MM-YYYY");
  const formatInputDate = (date) => moment(date, "DD-M-YYYY").format("YYYY-MM-DD");

  useEffect(() => {
    if(typeof data === 'object'){
      let newData = {};
      Object.keys(data).forEach(key => {
        if(/date/i.test(key)) {
          newData[key] = formatInputDate(data[key])
        }else {
          newData[key] = data[key]
        }
      })
      setFormValue(newData)
    }
  }, [data])

  async function updateData(id, data){
    try {
      await donationRepo.apiUpdateDonationUpload({data: data}, id)
      Swal.fire(
        'Data Updated!',
        'Data Success Updated!',
        'success'
      )
      afterSubmit()
      onHide()
    } catch (error) {
      console.log(error)
    }
  }

  const typeField = (type) => {
    if (/email/i.test(type)) {
      return "email";
    } else if (/date/i.test(type)) {
      return "date";
    }else {
      return "text";
    }
  };

  const handleSubmitData = (data) => {
    updateData(data._id, data)
  }

  const getValidationSchema = () => {
    if(data.file_type === 'giving_sg' || data.file_type === 'give_asia'){
      return givingSGSchema
    }else{
      return null
    }
  }

  const validationSchema = getValidationSchema();


  return (
    <Modal
      show={show}
      onHide={onHide}
      size='lg'
    >
      <Modal.Header closeButton>
        <Modal.Title>Update Data</Modal.Title>
      </Modal.Header>
      <Formik 
        initialValues={data} 
        onSubmit={(values) => handleSubmitData(values)} 
        validationSchema={validationSchema}
        validateOnChange={true}
        validateOnMount={true}
        validateOnBlur={true}
      >
        {({ errors, handleChange, setFieldValue }) => (
          <Formmik>
            <Modal.Body>
            {Object.keys(formValue).length > 0 && 
              <Row>
                {Object.keys(data).map((key, index) => {
                  const label = key.split('_').map(x => x.charAt(0).toUpperCase() + x.substr(1)).join(' ');
                  return (
                  ['_id','__v','file_type'].includes(key) ? <></> :
                  <Col xs={12} md={6} key={index}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label>{label}</Form.Label>
                      {/* {!/tdr/.test(key) ? */}
                      <Form.Control 
                        name={key}
                        type={typeField(key)}
                        placeholder={label}
                        value={formValue[key]}
                        onChange={(e) => {
                          if(/date/i.test(key)) {
                            setFieldValue(key, formatDate(e.target.value))
                          }else {
                            handleChange(e || "")
                          }
                          setFormValue({...formValue, [key]: e.target?.value || ""})
                        }}
                        isInvalid={errors[key]}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors[key]}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                )})}
              </Row>
            }
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" type="submit">
                Save
              </Button>
            </Modal.Footer>
          </Formmik>
        )}
      </Formik>
    </Modal>
  );
}
