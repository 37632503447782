import React from 'react'
import { Header } from '../../component/UploadNavbar'
import { FormUpload } from './FormUpload'

export function UploadDonation(){

    return(
        <>
            <Header />
            <FormUpload />
        </>
    )
}