import axios from 'axios';
import jwt_decode from "jwt-decode";
import store from "../../redux/store";
import * as authAction from "../modules/Auth/redux/authAction";

// const API_URL = 'http://localhost:3001';
const API_URL = process.env.REACT_APP_ENV === "production" ? 'https://vms.nkfs.org/api' : '	https://nkf-uploading.interaktiv.sg/api';

export const methodService = {
  GET: 'GET',
  POST: 'POST',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
  PUT: 'PUT'
};

const config = {
  baseURL: API_URL,
  timeout: 10000,
}

const getHeaders = async (isMultipart) => {
  return {
    Accept: 'application/json',
    'Content-Type': isMultipart ? 'multipart/form-data' : 'application/json',
  }
}

const instance = axios.create(config);

instance.interceptors.request.use(async request => {
  const {
    signup: { signupVerification }
  } = store.getState();
  const {
    auth: { setupPasswordToken, authToken }
  } = store.getState();
  request.headers = getHeaders(request.multipart)
  if (signupVerification?.data.temp_token){
    request.headers['Authorization'] = signupVerification?.data.temp_token;
  }
  if (setupPasswordToken !== null){
    request.headers['accesstoken'] = setupPasswordToken;
  }
  if (authToken !== null && authToken !== undefined){
    let decodeToken = jwt_decode(authToken);
    let dateNow = Math.floor(new Date().getTime() / 1000);
    if(dateNow > decodeToken.exp){
      store.dispatch(authAction.loginReset())
    } else {
      request.headers['accesstoken'] = authToken;
    }
  }

  return request;
})

export const apiService = async (url, method, data, params, multipart = false) => {
  
  const service = await instance({
    url: url,
    method: method,
    data: data,
    params: params,
    timeout: 60000,
    multipart
  });

  return service;
};
