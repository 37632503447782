/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import { SignupPage } from "./modules/Signup/pages/SignupPage"
import { SignupValidationPage } from "./modules/Signup/pages/SignupValidationPage"
import { SignupCompleteFormPage } from "./modules/Signup/pages/SignupCompleteFormPage";
import { SignupResultFormPage } from "./modules/Signup/pages/SignupResultFormPage"
import { InvitationEventPage } from "./modules/Invitation/pages/InvitationEventPage";
import { InvitationOrientationAttendancePage } from "./modules/Invitation/pages/InvitationOrientationAttendancePage";
import { InvitationVrPage } from "./modules/Invitation/pages/InvitationVrPage";
import { InvitationProgrammePage } from "./modules/Invitation/pages/InvitationProgrammePage";
import { SetupPasswordPage } from "./modules/Auth/pages/SetupPasswordPage";
import { TimesheetAttendanceVr } from "./modules/Timesheet/pages/AttendanceVr";
import { TimesheetAttendanceTraining } from "./modules/Timesheet/pages/AttendanceTraining";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import { DonationListPage } from "./modules/donationList";
import { DonationDetailPage } from "./modules/DonationDetail";
import { UploadDonation } from "./modules/uploadDonation"




export function Routes() {
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
    }),
    shallowEqual
  );

  return (
    <Switch>

      <Route path="/donation-list/detail/:uploadId" component={DonationDetailPage} />
      <Route path="/donation-list" component={DonationListPage} />
      <Route path="/" component={UploadDonation}/>
      //===== beta donation upload =====
    </Switch>
  );
}
