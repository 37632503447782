import { get } from "lodash";
import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Collapse,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import { ImBin } from "react-icons/im";
import Swal from "sweetalert2";
import * as donationRepo from "../../../datasources/donationRepo";
import { ModalInsert } from "../ModalInsert/ModalInsert";
import "./FormUpload.css";

export function FormUpload() {
  const [uploadFile, setUploadFile] = useState(null);
  const [channel, setChannel] = useState("Give Asia");
  const [loading, setLoading] = useState(false);
  const [delimiter, setDelimiter] = useState(",");
  const [selectedOptions, setSelectedOptions] = useState("upload");
  const [dataTypeStatus, setDataTypeStatus] = useState("start");
  const [dataTypeLoading, setDataTypeLoading] = useState(false);
  const [dataType, setDataType] = useState("");
  const [showModalInsert, setShowModalInsert] = useState(false);
  const [fields, setFields] = useState([]);
  const [rowsData, setRowsData] = useState([]);

  async function convertCSV() {
    const payload = new FormData();
    payload.append("file", uploadFile);
    payload.append("fileType", channel);
    payload.append("delimiter", delimiter);
    setLoading(true);
    try {
      const data = await donationRepo.apiUploadCsv(payload);
      Swal.fire("Data Uploaded!", "", "success");
      setUploadFile(null);
      setChannel("Give Asia");
      setDelimiter(",");
    } catch (error) {
      const errorMessage = error.response?.data?.message;
      Swal.fire(
        "Oops",
        `Error : ${errorMessage}`,
        // `Error : ${errorMessage}, <br> make sure you enter the right delimiter and the first line is the column name.`,
        "error"
      );
    } finally {
      setLoading(false);
    }
  }
  function uploadFileSubmit(file) {
    const filename = file.name;
    const namingFormat = channel.replace(" ", ".");
    if (file.type !== "text/csv") {
      Swal.fire(
        "Oops",
        `Error : The specified file ${file.name} cloud not be uploaded. <br> Only files with a .csv extension are allowed`,
        "error"
      );
    } else if(!filename.includes(namingFormat)) {
      Swal.fire(
        "Oops",
        `Error : Invalid file name <br> The filename must start with: ${namingFormat}-[name-file].csv for ${channel} files. Please rename your file and try again`,
        "error"
      );
    }
    else {
      setUploadFile(file);
    }
  }

  //====== function for batch insert

  async function onChangeDataType(value) {
    if (value) {
      try {
        setDataTypeLoading(true);
        setRowsData([]);
        const { data } = await donationRepo.apiUploadList({
          file_type: value,
          limit: 999,
        });
        if (get(data, "data", []).length > 0) {
          let fields = [];
          data.data.forEach((x) => {
            let currentKeys = Object.keys(x);
            currentKeys.forEach((key) => {
              if (!fields.includes(key)) {
                fields.push(key);
              }
            });
          });
          setFields(fields);
          setDataTypeStatus("available");
          setDataType(value);
        } else {
          setDataTypeStatus("error");
          setDataType("");
        }
      } catch (error) {
        const errorMessage = error.response?.data?.error;
        Swal.fire("Oops", `Error : ${errorMessage}`, "error");
      } finally {
        setDataTypeLoading(false);
      }
    } else {
      setDataTypeStatus("start");
      setDataType("");
    }
  }

  const handleShowModalInsert = () => {
    setShowModalInsert(true);
    // setModalIsHidden(false);
  };

  const handleCloseModalInsert = () => {
    setShowModalInsert(false);
    // setModalIsHidden(true);
  };

  function afterSubmit(formValue) {
    setRowsData((prevState) => [...prevState, { ...formValue }]);
  }

  function deleteRow(index) {
    let data = rowsData.slice();
    if (data.length > 0) {
      data.splice(index, 1);
      setRowsData(data);
    }
  }
  async function submitBatch() {
    try {
      if (rowsData.length > 0) {
        await donationRepo.apiUploadBatch({
          data: rowsData,
          fileType: dataType,
        });
        Swal.fire("Data Created!", "Batch Data Success Created!", "success");
        setRowsData([]);
      } else {
        Swal.fire("Error", `Please add some data row!`, "error");
      }
    } catch (error) {
      Swal.fire("Error", `${error.message}`, "error");
    }
  }

  const displayRowData = {
    giving_sg: ["disbursement_batch_no", "amount", "donor_name", "nric_fin"],
    give_asia: [
      "disbursement_batch_no",
      "amount",
      "donor_name",
      "nric_fin",
      "donation_type",
    ],
  };

  return (
    <div className="container-form-upload">
      {/* //---------new component */}
      <Row>
        <Col xl={3} style={{ padding: "5px" }}>
          <div className="section-card" style={{ height: "80vh" }}>
            <h2>Import Data</h2>
            <hr />
            <div
              className={`card-options ${selectedOptions === "upload" &&
                "card-selected"}`}
              onClick={() => setSelectedOptions("upload")}
            >
              <span className="card-title">CSV Upload</span>
            </div>
            <div
              className={`card-options ${selectedOptions === "batch" &&
                "card-selected"}`}
              onClick={() => setSelectedOptions("batch")}
            >
              <span className="card-title">Batch Insert</span>
            </div>
          </div>
        </Col>
        <Col xl={9} style={{ padding: "5px" }}>
          <div className="section-card">
            <Collapse in={selectedOptions === "upload"}>
              <Card>
                <Card.Body>
                  <h1 className="card-title">CSV Upload</h1>
                  <hr />
                  <div style={{ marginBottom: "10px" }}>
                    <label class="custom-file-upload">
                      <Form.File id="formcheck-api-regular">
                        <Form.File.Input
                          accept=".csv"
                          onChange={(e) => uploadFileSubmit(e.target.files[0])}
                          disabled={loading}
                        />
                      </Form.File>
                      Choose File
                    </label>
                    <span style={{ marginLeft: "10px" }}>
                      {uploadFile ? uploadFile.name : "No file choosen"}
                    </span>
                  </div>
                  <Row>
                    <Col xl={6}>
                      <p style={{ margin: "2px" }}>File Type</p>
                      <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                        <Form.Control
                          as="select"
                          size="md"
                          custom
                          onChange={(e) => setChannel(e.target.value)}
                          disabled={loading}
                        >
                          <option value="Give Asia">Give Asia</option>
                          <option value="Giving SG">Giving SG</option>
                          <option value="AXS">AXS</option>
                          {/* <option value="SGGiving">SGGiving</option> */}
                          <option value="Singtel Dash">Singtel Dash</option>
                          <option value="Simply Giving">Simply Giving</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col xl={6}>
                      <p style={{ margin: "2px" }}>Csv File Delimiter</p>
                      <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                        <Form.Control
                          as="select"
                          size="md"
                          custom
                          onChange={(e) => setDelimiter(e.target.value)}
                          disabled={loading}
                        >
                          <option value=","> , (Comma)</option>
                          <option value=";"> ; (Semicolon)</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <div>
                    {" "}
                    <span className="info-upload">
                      *) Only accept .CSV file
                    </span>
                  </div>
                  <Button
                    style={{
                      backgroundColor: "#337ab7",
                      borderColor: "#2e6da4",
                      width: "100%",
                      marginTop: "15px",
                      fontFamily: "Helvetica Neue",
                      display: "flex",
                      gap: "6px",
                      justifyContent: "center",
                      flexDirection: "row",
                      alignItems: "center"
                    }}
                    className="submit-upload"
                    onClick={convertCSV}
                    disabled={loading}
                  >
                    Submit
                    {loading && (
                      <span className="ml-3 spinner spinner-white"></span>
                    )}
                  </Button>
                </Card.Body>
              </Card>
            </Collapse>

            <Collapse in={selectedOptions === "batch"}>
              <Card
                style={{ borderColor: "1px solid #333", marginTop: "20px" }}
              >
                <Card.Body>
                  <h1 className="card-title">Batch Insert</h1>
                  <hr />
                  <div className="batch-form-container">
                    <p style={{ margin: "2px" }}>Choose Data Type</p>
                    <Row>
                      <Col xl={6}>
                        <Form.Control
                          as="select"
                          size="md"
                          custom
                          onChange={(e) => onChangeDataType(e.target.value)}
                        >
                          <option value="">-Select Type-</option>
                          <option value="give_asia">Give Asia</option>
                          <option value="giving_sg">Giving SG</option>
                          <option value="other">Error example</option>
                        </Form.Control>
                        {dataTypeStatus === "error" && (
                          <p
                            style={{
                              marginTop: "10px",
                              fontWeight: 200,
                              color: "red",
                            }}
                          >
                            Data type unavailable! make sure to do CSV Upload
                            with this data type for system know specific column
                            needed.
                          </p>
                        )}
                      </Col>
                      <Col xl={6}>
                        {dataTypeLoading && <Spinner animation="border" />}
                        {!dataTypeLoading && dataTypeStatus === "available" && (
                          <Button
                            style={{
                              backgroundColor: "#fff",
                              borderColor: "#337ab7",
                              width: "70%",
                              marginTop: "0px",
                              fontFamily: "Helvetica Neue",
                              color: "#337ab7",
                            }}
                            className="submit-upload"
                            onClick={handleShowModalInsert}
                          >
                            {loading && (
                              <span className="ml-3 spinner spinner-white"></span>
                            )}
                            Add Batch
                          </Button>
                        )}
                      </Col>
                    </Row>
                    <hr />
                    <h1 className="card-title" style={{ fontSize: "16px" }}>
                      Batch List
                    </h1>
                    {rowsData.length === 0 && (
                      <div className="empty-placeholder">
                        <h1>No Batch List</h1>
                        <span>Please add batch data on the above!</span>
                      </div>
                    )}
                    <div className="row-batch-container">
                      {rowsData.map((row, i) => (
                        <div className="row-batch" key={i}>
                          <Row>
                            <Col xl={11} className="batch-col">
                              <Row style={{ width: "100%" }}>
                                {displayRowData[dataType] &&
                                  displayRowData[dataType].map((field) => (
                                    <Col
                                      lg={Math.floor(
                                        12 / displayRowData[dataType].length
                                      )}
                                      className="data-col"
                                    >
                                      <h4 className="field-name">
                                        {field
                                          .split("_")
                                          .map(
                                            (x) =>
                                              x.charAt(0).toUpperCase() +
                                              x.substr(1)
                                          )
                                          .join(" ")}
                                      </h4>
                                      {row[field]}
                                    </Col>
                                  ))}
                              </Row>
                            </Col>
                            <Col
                              xl={1}
                              className="batch-col"
                              style={{ justifyContent: "center" }}
                            >
                              <Button
                                variant="danger"
                                style={{
                                  width: "40px",
                                  height: "35px",
                                  borderRadius: "4px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                                onClick={() => deleteRow(i)}
                              >
                                <ImBin />
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      ))}
                    </div>
                    <Button
                      style={{
                        backgroundColor: "#337ab7",
                        borderColor: "#2e6da4",
                        width: "100%",
                        marginTop: "20px",
                        fontFamily: "Helvetica Neue",
                        float: "right",
                      }}
                      disabled={dataTypeStatus !== "available"}
                      className="submit-upload"
                      onClick={submitBatch}
                    >
                      {loading && (
                        <span className="ml-3 spinner spinner-white"></span>
                      )}
                      Submit Batch
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </Collapse>
          </div>
        </Col>
      </Row>
      <ModalInsert
        onHide={handleCloseModalInsert}
        show={showModalInsert}
        fields={fields}
        afterSubmit={afterSubmit}
      />
    </div>
  );
}
