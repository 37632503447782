import { get } from 'lodash';
import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, Col, Collapse, Row, Spinner } from "react-bootstrap";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { useHistory, useLocation, useParams } from "react-router-dom";
import * as donationRepo from "../../../datasources/donationRepo";
import { ModalFilter } from "../../donationList/ModalUpdate/ModalUpdate";

import "./InfoDetail.css";
import { getErrorMessage, validationsData } from './helpers';



export function InfoDetail() {
  const [data, setData] = useState({})
  const [openSalesforce, setOpenSalesforce] = useState(false)
  const [modalShow, setModalShow] = useState(false)
  const [salesforceLoading, setSalesforceLoading] = useState(false)
  const [loadingData, setLoadingData] = useState(false)
  const [salesforceData, setSalesforceData] = useState({})
  const [metaData, setMetaData] = useState({})
  const [pagination, setPagination] = useState({})
  const [openPaymentDetail, setOpenPaymentDetail] = useState(false)
  const history = useHistory();
  const location = useLocation()
  let { uploadId } = useParams();
  const [wrongField, setWrongField] = useState([]);

  useEffect(() => {
    getDetail(uploadId)
  }, [uploadId])

  function categorizeSalesforce(data){
    const categoryList = {
      main: ['Owner', 'Donation_Date__c', 'RecordType', 'Donation_Status__c', 'Name',
            'Tax_Receipt_No__c', 'Donation_Datetime__c', 'X3rd_Ref_No__c', 'Cleared_Datetime__c', 'Donor_Type__c', 'Donor_Account__r',
            'Rejected_Date__c', 'Donor_Name__r', 'Reason_for_Cancellation__c', 'Tax_Deductible__c',
            'Batch_No__c', 'Electronic_Receipt_Sent__c', 'Print_Receipt__c',
            'Discrepancy__c', 'Receipt_Sent__c', 'Organisation_Name_Entered__c',],
      payment_details: ['Donation_Amount__c', 'Programme_Event__r', 'Payment_Method__c', 'Foreign_Currency_Amount__c', 'Bank__c',
                        'Foreign_Currency_Type__c', 'Donation_Type__c', 'Auth_Code__c', 'Donation_Category__c', 
                        'Deposit_to__c', 'Import__c'],
      recurring: [ 'Email_Entered__c', 'Full_Name_Entered__c', 'Address_Entered__c',
                  'ID_Type__c',],
      remarks: ['Remarks__c', 'Remarks_Import__c'],
      system_details: ['CreatedBy', 'LastModifiedBy']
    };
    const result = {};

    Object.keys(categoryList).forEach(category => {
      const obj = {}
      categoryList[category].forEach(key => {
        obj[key] = data[key];
      })
      result[category] = obj
    })
    return result
  }

  const setErrorMessage = (data, file_type) => {
    if(file_type === 'giving_sg' || file_type === 'give_asia'){
      Object.keys(data).map((key) => {
        if(!['_id','__v','file_type','salesforce_id'].includes(key)) {
          const error = getErrorMessage(key, data[key], data.donor_type, data.tdr)
          if(error){
            setWrongField(prev => [...prev, error])
          }
        }
      })
    }
  }

  async function getDetail(id){
    setWrongField([])
    try {
      setLoadingData(true)
      const { data: { data, pagination } } = await donationRepo.apiDetailUpload(id)
      const sf_id = data.salesforce_id
      setPagination(pagination || {})
      setData(data)
      setErrorMessage(data, data.file_type)
      setLoadingData(false)
      if(sf_id){
        setSalesforceLoading(true)
        const response = await donationRepo.apiGetDetailUpload(sf_id)
        const salesforceData = response.data?.donation;
        const metaObj = {}
        salesforceData.metaData.forEach(element => {
          metaObj[element.fullName] = element.label
        });
        const data = salesforceData?.data
        delete data.attributes

        const categorized = categorizeSalesforce(data)

        setMetaData(metaObj)
        setSalesforceData(categorized)
      }
      
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingData(false)
      setSalesforceLoading(false)
    }
  }

  return (
    <div className="container-info-detail" style={{
      height: !openSalesforce && '100%' ,
    }}>
      <div className="container-content-detail">
        <div className="card-detail">
          <ButtonGroup>
            {
              data.file_type && 
              <Button
                className="btn-cancel-detail "
                variant="outline-secondary"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "0 auto",
                  backgroundColor: "#d4d4d4",
                }}
                onClick={() => {
                  history.push(`/donation-list#${data.file_type}`)
                }}
              >
                <div>
                  <span className="btn-cancel" style={{ fontWeight: "bold" }}>
                    Back
                  </span>
                </div>
              </Button>
            }
            {
              !data.salesforce_id && 
              <Button
                className="btn-cancel-detail "
                variant="outline-secondary"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "0 auto",
                  backgroundColor: "#3d84b8",
                }}
                onClick={() => setModalShow(true)}
              >
                <div>
                  <span className="btn-cancel" style={{ color: "white" }}>
                    Edit
                  </span>
                </div>
              </Button>
            }
            
          </ButtonGroup>
          <ButtonGroup style={{float: 'right'}}>
          {
            uploadId !== pagination.oldest &&
            <Button
              className="btn-cancel-detail "
              variant="outline-secondary"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "0 auto",
                backgroundColor: "#d4d4d4",
              }}
              onClick={() => {
                history.push(`/donation-list/detail/${pagination.oldest}`)
              }}
            >
              <div>
                <span className="btn-cancel" style={{ fontWeight: "bold" }}>
                  Oldest
                </span>
              </div>
            </Button>
          }

          {
            uploadId !== pagination.latest &&
              <Button
                className="btn-cancel-detail "
                variant="outline-secondary"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "0 auto",
                  backgroundColor: "#3d84b8",
                }}
                onClick={() => {
                  history.push(`/donation-list/detail/${pagination.latest}`)
                }}
              >
                <div>
                  <span className="btn-cancel" style={{ color: "white" }}>
                    Latest
                  </span>
                </div>
              </Button> 
          }

          {
            pagination.previous !== undefined &&
              <Button
                className="btn-cancel-detail "
                variant="outline-secondary"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "0 auto",
                  backgroundColor: "#f5bc42",
                }}
                onClick={() => {
                  history.push(`/donation-list/detail/${pagination.previous}`)
                }}
              >
                <div>
                  <span className="btn-cancel" style={{ color: "white" }}>
                    Previous
                  </span>
                </div>
              </Button>
          }
          
          {
            pagination.next !== undefined &&
              <Button
                className="btn-cancel-detail "
                variant="outline-secondary"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "0 auto",
                  backgroundColor: "#28bd48",
                }}
                onClick={() => {
                  history.push(`/donation-list/detail/${pagination.next}`)
                }}
              >
                <div>
                  <span className="btn-cancel" style={{ color: "white" }}>
                    Next
                  </span>
                </div>
              </Button>
          }
          
        </ButtonGroup>
        </div>
      </div>

      <div className="container-content-detail">
        <div className="card-detail">
          <h1 className="title-detail">Donation Detail</h1>
          <hr />
          {loadingData && <Spinner animation="border" />}
          {!loadingData &&
            <Row>
              {
                data.salesforce_id &&
                <Col xl={6} xs={12}>
                  <div>
                    <Row>
                      <Col xl={5}>
                        <p className="content-detail">Salesforce Id</p>
                      </Col>
                      <Col xl={7}>
                        {" "}
                        <p style={{ margin: "0" }}>
                        <a
                          href={`https://thenationalkidneyfoundation2--psb.sandbox.lightning.force.com/lightning/r/Donation__c/${data.salesforce_id}/view`}
                          target="_blank"
                          style={{
                            fontFamily: "Helvetica Neue",
                          }}
                        >
                          {data.salesforce_id}
                        </a>
                        </p>
                      </Col>
                    </Row>
                  </div>
                  <hr />
                </Col>
              }
              {
                Object.keys(data).map((key, index) => (
                  ['_id','__v','file_type','salesforce_id'].includes(key) ? null :
                  <Col xl={6} xs={12} key={index}>
                    <div>
                      <Row>
                        <Col xl={5}>
                          <p className="content-detail">{key.split('_').map(x => x.charAt(0).toUpperCase() + x.substr(1)).join(' ')}</p>
                        </Col>
                        <Col xl={7}>
                          <p style={{ margin: "0" }}>
                            { 
                              data.file_type === "giving_sg" || data.file_type === "give_asia" ?
                              validationsData(key, data[key], data.donor_type, data.tdr) ?  
                                  data[key] || "-" : 
                                  <div className='wrong-field'>
                                    {data[key] || "-"}
                                    <span>X</span>
                                  </div>
                                :
                                data[key] || "-"
                            }
                          </p>
                        </Col>
                      </Row>
                    </div>
                    <hr />
                  </Col>
                ))
              }
              {
                data.file_type === "giving_sg" || data.file_type === "give_asia" ?
                <>
                  <Col xl={6} xs={12} key="error">
                    <div>
                      <Row>
                        <Col xl={5}>
                          <p className="content-detail">Errors</p>
                        </Col>
                        <Col xl={7}>
                          {wrongField.map((field, index) => (
                            <div key={index}>
                              {field}
                            </div>
                          ))}
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col xl={6} xs={12} key="error-checkbox">
                    <div>
                      <Row>
                        <Col xl={5}>
                          <p className="content-detail">Errors?</p>
                        </Col>
                        <Col xl={7}>
                          <input type='checkbox' checked={wrongField.length > 0} />
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </>
                : null
              }
            </Row>
          }
        </div>
      </div>

      <div className="container-content-detail">
        {loadingData && <Spinner animation="border" />}
        {
          !loadingData && data.salesforce_id &&
          <div className="card-detail">
            <h1 
              className="title-detail" 
              style={{display:'flex', alignItems:'center', fontWeight: 500, cursor:'pointer', marginBottom:0}}
              onClick={() => setOpenSalesforce(prev => (!prev))}
            >
              Salesforce Detail
              {
                openSalesforce ? 
                <MdKeyboardArrowUp className="arrow-navigation-icon"/> :
                <MdKeyboardArrowDown className="arrow-navigation-icon"/>
              }
            </h1> 
            <Collapse in={openSalesforce}>
              {
              data.salesforce_id && salesforceLoading ? <Spinner animation="border" /> :
              <div>
                <hr/>
                <Row>
                  {
                    salesforceData['main'] &&
                    Object.keys(salesforceData['main']).map((key, index) => (
                      ['attributes','Id'].includes(key) ? null :
                      <Col xl={6} xs={12} key={index}>
                        <div>
                          <Row>
                            <Col xl={6}>
                              <p className="content-detail">
                                {
                                  metaData[key] !== undefined ? 
                                  metaData[key] : 
                                  key.replace(/([A-Z])/g, ' $1').replace(/^./, function(str){ return str.toUpperCase(); })
                                  .split('__r')[0].split('_').join(' ')
                                }
                              </p>
                            </Col>
                            <Col xl={6}>
                              {" "}
                              <p style={{ margin: "0" }}>
                                {
                                  salesforceData['main'][key] !== null && typeof salesforceData['main'][key] === 'object' ? 
                                  salesforceData['main'][key].Name : 
                                  get(salesforceData['main'], key, '')
                                }
                              </p>
                            </Col>
                          </Row>
                        </div>
                        <hr />
                      </Col>
                    ))
                  }
                </Row>
                <h2 className="title-detail" >
                    Payment Details
                </h2> 
                <hr/>
                <Row>
                  {
                    salesforceData['payment_details'] &&
                    Object.keys(salesforceData['payment_details']).map((key, index) => (
                      ['attributes','Id'].includes(key) ? null :
                      <Col xl={6} xs={12} key={index}>
                        <div>
                          <Row>
                            <Col xl={6}>
                              <p className="content-detail">
                                {
                                  metaData[key] !== undefined ? 
                                  metaData[key] : 
                                  key.replace(/([A-Z])/g, ' $1').replace(/^./, function(str){ return str.toUpperCase(); })
                                  .split('__r')[0].split('_').join(' ')
                                }
                              </p>
                            </Col>
                            <Col xl={6}>
                              {" "}
                              <p style={{ margin: "0" }}>
                                { 
                                  salesforceData['payment_details'][key] !== null && typeof salesforceData['payment_details'][key] === 'object' ? 
                                  salesforceData['payment_details'][key].Name : 
                                  get(salesforceData['payment_details'], key, '')
                                }
                              </p>
                            </Col>
                          </Row>
                        </div>
                        <hr />
                      </Col>
                    ))
                  }
                </Row>
                <h2 className="title-detail" >
                    Recurring
                </h2> 
                <hr/>
                <Row>
                  {
                    salesforceData['recurring'] &&
                    Object.keys(salesforceData['recurring']).map((key, index) => (
                      ['attributes','Id'].includes(key) ? <></> :
                      <Col xl={6} xs={12} key={index}>
                        <div>
                          <Row>
                            <Col xl={6}>
                              <p className="content-detail">
                                {
                                  metaData[key] !== undefined ? 
                                  metaData[key] : 
                                  key.replace(/([A-Z])/g, ' $1').replace(/^./, function(str){ return str.toUpperCase(); })
                                  .split('__r')[0].split('_').join(' ')
                                }
                              </p>
                            </Col>
                            <Col xl={6}>
                              {" "}
                              <p style={{ margin: "0" }}>
                                {
                                  salesforceData['recurring'][key] !== null && typeof salesforceData['recurring'][key] === 'object' ? 
                                  salesforceData['recurring'][key].Name : 
                                  get(salesforceData['recurring'], key, '')
                                }
                              </p>
                            </Col>
                          </Row>
                        </div>
                        <hr />
                      </Col>
                    ))
                  }
                </Row>
                <h2 className="title-detail">
                  Remarks
                </h2> 
                <hr/>
                <Row>
                  {
                    salesforceData['remarks'] &&
                    Object.keys(salesforceData['remarks']).map((key, index) => (
                      ['attributes','Id'].includes(key) ? <></> :
                      <Col xl={6} xs={12} key={index}>
                        <div>
                          <Row>
                            <Col xl={6}>
                              <p className="content-detail">
                                {
                                  metaData[key] !== undefined ? 
                                  metaData[key] : 
                                  key.replace(/([A-Z])/g, ' $1').replace(/^./, function(str){ return str.toUpperCase(); })
                                  .split('__r')[0].split('_').join(' ')
                                }
                              </p>
                            </Col>
                            <Col xl={6}>
                              {" "}
                              <p style={{ margin: "0" }}>
                                {
                                  salesforceData['remarks'][key] !== null && typeof salesforceData['remarks'][key] === 'object' ? 
                                  salesforceData['remarks'][key].Name : 
                                  get(salesforceData['remarks'], key, '')
                                }
                              </p>
                            </Col>
                          </Row>
                        </div>
                        <hr />
                      </Col>
                    ))
                  }
                </Row>
                <h2 className="title-detail">
                  System Details
                </h2> 
                <hr/>
                <Row>
                {
                  salesforceData['system_details'] &&
                    Object.keys(salesforceData['system_details']).map((key, index) => (
                      ['attributes','Id'].includes(key) ? <></> :
                      <Col xl={6} xs={12} key={index}>
                        <div>
                          <Row>
                            <Col xl={6}>
                              <p className="content-detail">
                                {
                                  metaData[key] !== undefined ? 
                                  metaData[key] : 
                                  key.replace(/([A-Z])/g, ' $1').replace(/^./, function(str){ return str.toUpperCase(); })
                                  .split('__r')[0].split('_').join(' ')
                                }
                              </p>  
                            </Col>
                            <Col xl={6}>
                              {" "}
                              <p style={{ margin: "0" }}>
                                {
                                  salesforceData['system_details'][key] !== null && typeof salesforceData['system_details'][key] === 'object' ? 
                                  salesforceData['system_details'][key].Name : 
                                  get(salesforceData['system_details'], key, '')
                                }
                              </p>
                            </Col>
                          </Row>
                        </div>
                        <hr />
                      </Col>
                    ))
                  }
                </Row>
              </div>
              }
            </Collapse>
          </div>
        }
        <ModalFilter
            onHide={() => setModalShow(false)}
            show={modalShow}
            data={data}
            afterSubmit={() => getDetail(uploadId)}
          />
      </div>
    </div>
  );
}
